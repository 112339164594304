import React from 'react';
import PropTypes from 'prop-types';
import AddImg from '/add.svg';
import DeleteImg from '/delete.png';
import { Button } from '..';
import './SubHeader.css';

function SubHeader({ name, onDeleteItem, children }) {

  const autenticado = localStorage.getItem('token')

  return (
    <div className="subheader-container">
      <p className="rc-title-page">{name}</p>
      {children}
      {autenticado && (
        
        <div className="list-options">
          
        <Button title="Add" link="/articles/new">
          <img className="imagem-add" src={AddImg} alt="Add new" width="50px"/>
        </Button>
      </div>
      )}
    </div>
  );
}

SubHeader.propTypes = {
  name: PropTypes.string.isRequired,
};

export default SubHeader;
