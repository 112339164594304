import React, { useState } from "react";
import api from "../../services/Api";
import "./CreateSubcateg.css";

function CreateSubcateg({ catId, closeModal }) {
    const [input, setinput] = useState('');

    const registerSubcategory = async () => {
        try{
            await api.post('/subcategories', { description: input, category_id: catId });
        } catch(error){
            console.log(error)
        }
        setinput('')
        closeModal()
    }

    return (
        <div className="main-container">
            <div className="input-container">
                <h3 className="criar-subcategory-title">Criar Subcategoria </h3>
                <input type="text" className="input-subcategory" value={input} onChange={(e) => setinput(e.target.value)} />
            </div>
            <div className="btn-enviar">
                <input type="button" value="Enviar" className="submit-subcategory" onClick={registerSubcategory} />
            </div>
        </div>
    );
}

export default CreateSubcateg;