import React, { useEffect } from 'react'
import { useState } from 'react';
import { Header, Footer, ListItems } from '../../components';
import api from '../../services/Api';
import './Subcategories.css'

function Subcategories() {
  const [subcatinfo, setSubcatinfo] = useState();
  const [allArticles, setAllArticles] = useState([]);
  const pathnameArr = window.location.pathname.split('/')
  const id = pathnameArr[pathnameArr.length - 1];
  const onInit = async () => {
    const res = await api.get('/articles');
    const res2 = await api.get(`/subcategories/${id}`)
    console.log(res2.data)
    setAllArticles(res.data);
    setSubcatinfo(res2.data);
  }

  useEffect(() => {
    onInit();
  }, [])
  return (
    <>
      <Header />
      <div className='main-pag'>
        <p className='rc-title-page'>{subcatinfo ? subcatinfo.description : 'loading'} </p>
        {
          allArticles.length
            ? allArticles
              .filter((article) => article.subcategories.id === parseInt(id))
              .map((article) => <li className='lista'><a className='link' href={`/articles/${article.id}`}>{article.title}</a></li>)
            : <span>Carregando</span>
        }
      </div>
      <Footer />
    </>

  )
}


export default Subcategories;