import React from 'react';
import {
  arrayOf, objectOf, string, func,
} from 'prop-types';
import EditImg from '/edit.png';
import DeleteImg from '/delete.png';
import { Link } from 'react-router-dom';
import { Button } from '..';
import './ListItems.css';

function ListItems({ list, type, allArticles }) {

  const autenticado = localStorage.getItem('token')

  //link
  return (

    <ul className="list-container">
      {list.map(({ id, description }) => (
        <li key={description} >
          <a className="subcategory-link" href={`/subcategories/${id}`}>
            {type === 'article'
              ? (<Link to={`/articles/${id}`}>{title}</Link>)
              : description}
            {autenticado && ('')}

          </a>

          <div className="articles">
            <ul>
              {
                allArticles
                  .filter((art, index) => art.subcategories.id === id)
                  .filter((art, index) => index < 5)
                  .map((art) => (
                    <li ><a className='article-style' href={`/articles/${art.id}`}>{art.title}</a></li>
                  ))
              }
              {
                allArticles.filter((art) => art.categoryId === id).length > 5 && (
                  <li><a href={`/categories/${id}`}>ver mais </a></li>
                )
              }
            </ul>
          </div>

        </li>
      ))}
    </ul>
  );
}

ListItems.propTypes = {
  list: arrayOf(objectOf(string)).isRequired,
  onDeleteItem: func.isRequired,
  type: string,
};

ListItems.defaultProps = {
  type: '',
};

export default ListItems;

//key={description}