import React from "react";
import "./styles.css";

export default function Modal({ visible, content, size, clickOut, overflow, type = "modal-content" }) {
  // Ao clicar fora, chama callback (geralmente é uma função para fechar)
  window.onclick = (event) => {
    if (event.target.classList.contains("modal-flex")) {
      clickOut !== undefined ? clickOut() : (visible = false);
    }
  };

  return (
    <div className={`modal ${visible ? "modal-visible" : "modal-hidden"} flex`}>
      <div className="modal-flex flex flex-center">
        <div className={`${overflow ? "modal-content-overflow": type} modal-${size ?? "normal"}`}>
          {content}
        </div>
      </div>
    </div>
  );
}