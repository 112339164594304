import React, { useState, useEffect } from 'react';
import { Header, Footer, Button } from '../../components';
import { useNavigate } from 'react-router-dom';
import { alertError } from '../../helpers/SweetAlert';
import loadingGif from '/biud.gif';
import api from '../../services/Api';
import './Login.css';

function Login() {
    const [email, setEmail] = useState();
    const [senha, setSenha] = useState();
    const [loading, setLoading] = useState(true); //colocar false depois que funcionar
    const navigate = useNavigate();

    useEffect(() => {

        if (!localStorage.getItem('token')) {
            return;
        }
        navigate('/main', { replace: true });

    }, [])

    const setNewLogin = async (event) => {
        event.preventDefault();
        setLoading(true);
        await api.post('/login', { email, senha })
            .then(({ data }) => {
                localStorage.setItem('token', data.token)
                setLoading(false);
                return navigate('/main', { replace: true })
            })
            .catch((err) => {
                console.log(err)
                setLoading(false);
                alertError(`E-mail ou senha incorretos`);
            });
    };

    return (
        <>
            <Header />
            <div id="login">
                <h1 className="ti">ACESSAR BIUD FAQ</h1>
                <form className="form">

                    <div className="field">
                        <label htmlFor="email">E-mail</label>
                        <input type="email" name="email" id="email" onChange={(e) => setEmail(e.target.value)} />
                    </div>

                    <div className="field">
                        <label htmlFor="password">Senha</label>
                        <input type="password" name="Password" id="Password" onChange={(e) => setSenha(e.target.value)} />
                    </div>

                    <div className="actions">
                        <Button
                            name="Acessar"
                            className="btn-back botaoLogin"
                            onClick={(event) => setNewLogin(event)}
                            title="Acessar"
                            disabled={!email || !senha} // || =  ou
                        />
                    </div>
                </form>
            </div>
            <div className="loader-flex">
                {loading && <img src={loadingGif} alt="Loading..." width="150px" />}
            </div>
            <Footer />
        </>
    );
}


export default Login;