import React,{ useEffect}  from 'react';
import biudLogo from '/logo-biud.png';
import biudLogout from '/logout.png';
import { Link, useLocation   } from 'react-router-dom';
import { Button } from '../../components';
import './Header.css';
import { isAuthenticated } from '../../services/Auth';
import Login from '../../pages/Login/Login';

function Header() {
  function logout() {
    localStorage.removeItem('token');
  }

  const autenticado = localStorage.getItem('token')
  const {pathname} = useLocation();

  return (
    <div className="header-container">
      <Link to="/">
        <button type="button" title="Go to Homepage">
          <img src={biudLogo} alt="BIUD logo" width="40px" />
        </button>
      </Link>

      {autenticado && (
        <Link to="/login">
        <button type="button" title="Logout" onClick={logout}>
          <img src={biudLogout} alt="BIUD logout" width="40px" />
        </button>
      </Link>
      )}

{!autenticado && pathname !== '/login' &&(
            <Button
                name="Login"
                className="btn-back botao-login-header"
                link="/login"
                title="Acessar"
            />
      )}
    </div>
  );
}


export default Header;
