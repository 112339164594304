import React, { useEffect } from 'react'
import { useState } from 'react';
import { Header, Footer, ListItems, SubHeader, Buscar } from '../../components';
import api from '../../services/Api';
import './SubcategorieArt.css'

function SubcategorieArt() {
  const [allArticles, setAllArticles] = useState([]);
  const [artigosFiltrados, setArtigosFiltrados] = useState([]);
  const [catinfo, setCatinfo] = useState();
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState('');
  const [subcatList, setSubcatList] = useState();
  const pathnameArr = window.location.pathname.split('/')
  const id = pathnameArr[pathnameArr.length - 1];



  const onInit = async () => {
    const promise1 = api.get('/articles');
    const promise2 = api.get(`/categories/${id}`);
    const promise3 = api.get('/subcategories');
    const [res1, res2, res3] = await Promise.all([promise1, promise2, promise3]);
    setAllArticles(res1.data);
    setCatinfo(res2.data);
    setSubcatList(res3.data);
    setLoading(false);
  };

  useEffect(() => {
    onInit();
  }, []);

  // const articles = query.length > 0 ? artigosFiltrados : allArticles


  function handleChange(e) {
    e.preventDefault();
    setQuery(e.target.value);

    const temp = allArticles.filter((article) => article.title.toLowerCase().includes(e.target.value.toLowerCase())) //callback
    setArtigosFiltrados(temp);
  };

  return !loading && (
    <>
      <Header />
      <div className="Div-na-main">
        <div className="buscar-art">
          <p className="rc-title-page">{catinfo.description}</p>
          <Buscar
            value={query}
            handleChange={handleChange}
          />

          <h3 className='atividade'>{query.length > 0 ? `Buscando artigos contendo "${query}" no título` : ''}</h3>
          {(artigosFiltrados.length === 0 && query.length > 0) && <h3 className='procura'>Nenhum artigo encontrado </h3>}
        </div>



        <div className="subcategory-list">
          <ListItems
            list={subcatList.filter((subcat) => subcat.category_id === parseInt(id))}
            // list={subcatList.filter(
            //   (subcat) => subcat.category_id === parseInt(id)
            //     && subcatList.filter((subcat2) => subcatList.filter((art) => art.subcategories.id === subcat2.id).length > 0)
            // )}
            allArticles={
              query.length
                ? allArticles.filter((art) => art.title.toLowerCase().includes(query.toLowerCase()))
                : allArticles
            }
          />
        </div>
      </div>

      <Footer />
    </>

  )
}


export default SubcategorieArt;