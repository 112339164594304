import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <div className="footer-container">
      <p>BIUD </p>
      <p className="footer-dashe"> </p>
    </div>
  );
}

export default Footer;
