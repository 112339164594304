import React,{ useRef, useState } from "react";
import './Buscar.css'
import lupa from '/lupa.png'

function Buscar({value, handleChange}) {

  return (
        <div className="App"> 
        <div className="container-buscar">
          <form action="">
          <input className="buscar-txt" type="text" name="" placeholder="Pesquisa" value={value}
          onChange={handleChange}
          />
          </form>   
        </div> 
        </div>
    )
}

export default Buscar;


//como faria pelo front: 1-estado chamado artigos; criaria um outro estado chamado artigosFiltrados
//na hgr de renderizar, colocar render condicional. se tiver coisa dentro mostrar ele se não mostrar todos 
//como contralar artigos filtrados: função handleChangeInput, função fica no onchange do input