import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button, Footer, Header, Input, Modal, Select, CreateSubcateg
} from '../../components';
import { alertError, alertSucess } from '../../helpers/SweetAlert';
import api from '../../services/Api';
import loadingGif from '/biud.gif';
import './RegisterChangeArticle.css';
import Editor from '../../components/Editor/Editor';
import parse from 'html-react-parser';


function RegisterArticle() {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [categoryId, setCategoryId] = useState(null);
  const [subcategoryId, setsubcategoryId] = useState(null);
  const [categoriesList, setCategoriesList] = useState([]);
  const [subcategoriesList, setSubcategoriesList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const navigate = useNavigate();

  async function getSubcategories() {
    const res = await api.get('/subcategories')
    setSubcategoriesList(res.data);
  }

  useEffect(() => {
    const getCategories = async () => {
      setLoading(true);
      await api.get('/categories')
        .then(({ data }) => {
          setCategoriesList(data);
          // setCategoryId(data[0].id);
        })
        .then(setTimeout(() => {
          setLoading(false);
        }, 250))
        .catch((err) => {
          console.error(`ops! something is wrong: ${err}`);
        });
    };
    getCategories();
    getSubcategories();
  }, [modalVisible]);

  const setNewArticle = async (event) => {
    event.preventDefault();
    try {
      await api.post('/articles', { title, description, subcategoryId: parseInt(subcategoryId) });
      alertSucess('artigo', 'criado');
      navigate('/main', { replace: true });
    } catch (err) {
      console.error(`ops, something is wrong with the create API ${err}`);
      alertError('artigo', title, 'criar');
    }
  };

  const closeModal = () => setModalVisible(false);

  const options = subcategoriesList.filter((subcat) => subcat.category_id === parseInt(categoryId));

  return (
    <form onSubmit={(event) => setNewArticle(event)}>
      <Header />
      <div className="register-change-article-container">
        <p className="rc-title-page">Cadastrar novo artigo</p>
        <main className="inputs-container">
          {loading
            ? <img src={loadingGif} alt="Loading..." width="150px" />
            : (
              <>
                <Input
                  label="Título"
                  name="title"
                  id="title"
                  type="text"
                  value={title}
                  onChange={setTitle} description
                />

                <Editor
                  label="Descrição"
                  name="descricao"
                  id="descricao"
                  value={description}
                  onChangeDescription={setDescription}
                  className="description-input"
                />

                <Select
                  label="Categoria"
                  name="category"
                  id="category"
                  value={categoryId}
                  onChange={setCategoryId}
                  // defaultValue={categoriesList[0]?.id}
                  // defaultOption={categoriesList[0]?.description}
                  required="true"
                  options={categoriesList}

                />

                <Select
                  label="SubCategoria"
                  name="Subcategory"
                  id="subcategorias"
                  value={subcategoryId}
                  onChange={setsubcategoryId}
                  // defaultValue={options[0]?.id}
                  // defaultOption={options[0]?.description}
                  required="true"
                  options={options}

                />

                <div className="btn-criar">
                  <input disabled={!categoryId} type="button" value="Cadastrar Subcategoria" className={`criar ${!categoryId && 'hidden'}`} onClick={() => setModalVisible(true)} />
                </div>

                <section className="saveback-section">
                  <Button
                    name="Voltar"
                    className="btn-back"
                    link="/main"
                    title="Voltar"
                  />
                  <Input
                    type="submit"
                    name="Salvar"
                    className="btn-save"
                    title="Salvar"
                    value="Salvar"
                    link="/main"
                  />
                </section>
              </>
            )}

        </main>
      </div>
      <Footer />

      <Modal
        visible={modalVisible}
        content={<CreateSubcateg closeModal={closeModal} catId={categoryId} />}
        size='big'
        clickOut={closeModal}
      />
    </form>
  );
}

export default RegisterArticle;
