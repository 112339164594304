import axios from 'axios';
const baseURL = import.meta.env.VITE_REACT_APP_BACKEND_URL;
// const baseURL = process.env.VITE_REACT_APP_BACKEND_URL;
console.log('baseURL', baseURL);
let headers = {};

if (localStorage.token) {
  headers.Authorization = `Bearer  ${localStorage.token}`;
}

const api = axios.create({
  baseURL: baseURL,
  headers,
})

export default api;