import React,{ useRef, useState } from "react";
import JoditEditor from "jodit-react";
import './Editor.css'
import { object } from "prop-types";
 import parse from 'html-react-parser';

function Editor({onChangeDescription, value}) {
    const editor = useRef(null)
    const [content, setContent] = useState('') 

    const config = {
            readonly: false,
    }

    const handleUpdate = (event)  => {
        console.log(event.target)

        const editorContent = event.target.value
        setContent(editorContent)
    }

    return (
        <div className="App"> 
        
        <JoditEditor 
            ref={editor}
            value={value}
            config={config}
            onBlur={(e) => onChangeDescription(e)}
            //onChange={(e) => {onChangeDescription(e)}} 
        />
        </div>
    )
}

export default Editor;