import React, { useState } from 'react';
import { useEffect } from 'react';
import { Header, SubHeader, Footer, Button, Buscar } from '../components';
import api from '../services/Api';
import './Main.css';
import parse from 'html-react-parser';
import subheader from '/subheader.png';

function Main() { //componente
  const [allArticles, setAllArticles] = useState([]);
  const [artigosFiltrados, setArtigosFiltrados] = useState([]);
  const [query, setQuery] = useState('');
  const getInfo = async () => {
    const res = await api.get('/articles');
    setAllArticles(res.data);
  };

  useEffect(() => {
    getInfo();
  }, []);

  const articles = query.length > 0 ? artigosFiltrados : allArticles 

  function handleChange(e) {
    e.preventDefault(); //executando função; evitar reloading da page
    setQuery(e.target.value);

    const temp = allArticles.filter((article) => article.title.toLowerCase().includes(e.target.value.toLowerCase())) //callback

    setArtigosFiltrados(temp);
  };

  return (
    <>
      <Header
        className="header-container" />
      <SubHeader className="subheader">
        <Buscar
          value={query}
          handleChange={handleChange}
        />
      </SubHeader>
      <div className='master-container'>
        <div className="main-container">
          <Button
            name="Operação"
            link={`/categories/${1}`}
            className="btn-category"
          />

          <Button
            name="Tecnologia"
            link={`/categories/${2}`}
            className="btn-category"
          />
          {/* <Button
            name="Artigos"
            link="/articles"
            className="btn-articles"
          /> */}
        </div>
        <hr className='hr' />
        <h3 className='atividade'>{query.length > 0 ? `Buscando artigos contendo "${query}" no título` : 'Atividade recente'}</h3>
        {(artigosFiltrados.length === 0 && query.length > 0) && <h3 className='procura'>Nenhum artigo encontrado :(</h3>}

        <div className='atividade-recente'>
          {articles.filter((_a, index) => [0, 1, 2].includes(index)).map((article, index, articles) => { // função sort
            let showHr = true;
            if (articles.length - 1 === index) showHr = false;
            return (
              <article key={article.id}>
                <a className='atividade-recente-subtitulo' href={`/articles/${article.id}`}>{parse(article.title)}</a>
                {showHr && <hr className='teste' />}
              </article>
            )
          })}
        </div>
      </div>
      <Footer />
    </> 

  );
} 

export default Main;
